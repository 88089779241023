import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import ptBR from 'antd/es/locale/pt_BR'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import AcessoProvider from './components/Acesso/Provider'
import AcessoLoginRequired from './components/Acesso/LoginRequired'

import EAFullPageLoader from './components/UI/EAFullPageLoader'
import EAUncaughtErrorFeedback from './components/UI/EAUncaughtErrorFeedback'

import AcessoLoginPage from './pages/acesso/login'
import AcessoLogoutPage from './pages/acesso/logout'

import ControleAcessoPage from './pages/controle-acesso'
import DisponibilidadeProdutoPage from './pages/disponibilidade'
import NaoEncontradoPage from './pages/nao-encontrado'

import App from './App'

import './index.less'

const AdquirenteTefPages = lazy(() => import('./pages/adquirente-tef'))
const AliquotaPages = lazy(() => import('./pages/aliquota'))
const BairroPages = lazy(() => import('./pages/bairro'))
const BandeiraTefPages = lazy(() => import('./pages/bandeira-tef'))
const CartaoConsumoPages = lazy(() => import('./pages/cartao-consumo'))
const CidadePages = lazy(() => import('./pages/cidade'))
const CofinsPages = lazy(() => import('./pages/cofins'))
const DashboardPage = lazy(() => import('./pages/dashboard'))
const DispMovelPages = lazy(() => import('./pages/disp-movel'))
const EcfPages = lazy(() => import('./pages/ecf'))
const LocalEstoquePages = lazy(() => import('./pages/local-estoque'))
const EntradaNotaPages = lazy(() => import('./pages/entrada-nota'))
const EstabelecimentoPages = lazy(() => import('./pages/estabelecimento'))
const ExtrairCfePage = lazy(() => import('./pages/extrair-cfe'))
const FichaPages = lazy(() => import('./pages/ficha'))
const FinanceiroPages = lazy(() => import('./pages/financeiro'))
const FormaPgtoPages = lazy(() => import('./pages/forma-pgto'))
const GatilhoPages = lazy(() => import('./pages/gatilho'))
const GrupoOpcaoPages = lazy(() => import('./pages/grupo-opcao'))
const GrupoProdutoPages = lazy(() => import('./pages/grupo-produto'))
const ImpressoraComandaPages = lazy(() => import('./pages/impressora-comanda'))
const IntegracaoPages = lazy(() => import('./pages/integracao'))
const MaquinaPosPages = lazy(() => import('./pages/maquina-pos'))
const MarcaSatPages = lazy(() => import('./pages/marca-sat'))
const MesaPages = lazy(() => import('./pages/mesa'))
const MonitoramentoCfePages = lazy(() => import('./pages/monitoramento-cfe'))
const ModalidadeTefPages = lazy(() => import('./pages/modalidade-tef'))
const ModeloEcfPages = lazy(() => import('./pages/modelo-ecf'))
const MotivoCancelaPages = lazy(() => import('./pages/motivo-cancela'))
const MotivoTransferePages = lazy(() => import('./pages/motivo-transfere'))
const OpcaoPages = lazy(() => import('./pages/opcao'))
const OpNaoFiscalPages = lazy(() => import('./pages/op-nao-fiscal'))
const ParametrosPage = lazy(() => import('./pages/parametros'))
const PdvPages = lazy(() => import('./pages/pdv'))
const PessoaPages = lazy(() => import('./pages/pessoa'))
const PisPages = lazy(() => import('./pages/pis'))
const PracaPages = lazy(() => import('./pages/praca'))
const ProdutoPages = lazy(() => import('./pages/produto'))
const RegiaoPages = lazy(() => import('./pages/regiao'))
const SatPages = lazy(() => import('./pages/sat'))
const SetorProducaoPages = lazy(() => import('./pages/setor-producao'))
const SpedPages = lazy(() => import('./pages/sped'))
const TabelaPrecoPages = lazy(() => import('./pages/tabela-preco'))
const TaraPages = lazy(() => import('./pages/tara'))
const TransferenciaEstoquePages = lazy(() =>
  import('./pages/transferencia-estoque')
)
const UnidadePages = lazy(() => import('./pages/unidade'))

const RelatorioAcompanhamentoPrevendasPage = lazy(() =>
  import('./pages/relatorio/acompanhamento-prevendas')
)
const RelatorioAcompanhamentoPrevendasDeliveryPage = lazy(() =>
  import('./pages/relatorio/acompanhamento-prevendas-delivery')
)
const RelatorioAgendaEncomendasPage = lazy(() =>
  import('./pages/relatorio/agenda-encomendas')
)
const RelatorioAjusteEstoquePage = lazy(() =>
  import('./pages/relatorio/ajuste-estoque')
)
const RelatorioAuditoriaTaxaServicoPage = lazy(() =>
  import('./pages/relatorio/auditoria-taxa-servico')
)
const RelatorioAuditoriaVendasPorPracaPage = lazy(() =>
  import('./pages/relatorio/auditoria-vendas-por-praca')
)
const RelatorioAuditoriaVendasVarejofacilPage = lazy(() =>
  import('./pages/relatorio/auditoria-vendas-varejofacil')
)
const RelatorioCancelamentoPrevendasPage = lazy(() =>
  import('./pages/relatorio/cancelamento-prevendas')
)
const RelatorioClientesQueNaoCompraramPage = lazy(() =>
  import('./pages/relatorio/clientes-que-nao-compraram')
)
const RelatorioConsumoClientesPage = lazy(() =>
  import('./pages/relatorio/consumo-clientes')
)
const RelatorioCrediarioPage = lazy(() => import('./pages/relatorio/crediario'))
const RelatorioCurvaAbcPage = lazy(() => import('./pages/relatorio/curva-abc'))
const RelatorioDesempenhoAtendentePage = lazy(() =>
  import('./pages/relatorio/desempenho-atendente')
)
const RelatorioDesempenhoEntregadorPage = lazy(() =>
  import('./pages/relatorio/desempenho-entregador')
)
const RelatorioEstoquePage = lazy(() => import('./pages/relatorio/estoque'))
const RelatorioFaturamentoDiarioMensalPage = lazy(() =>
  import('./pages/relatorio/faturamento-diario-mensal')
)
const RelatorioFlashCaixaPage = lazy(() =>
  import('./pages/relatorio/flash-caixa')
)
const RelatorioInformacoesProdutosPage = lazy(() =>
  import('./pages/relatorio/informacoes-produtos')
)
const RelatorioListagemClientesPage = lazy(() =>
  import('./pages/relatorio/listagem-clientes')
)
const RelatorioOperacoesNaoFiscaisPage = lazy(() =>
  import('./pages/relatorio/operacoes-nao-fiscais')
)
const RelatorioProdutosPorClientePage = lazy(() =>
  import('./pages/relatorio/produtos-por-cliente')
)
const RelatorioProdutosVendidosPorOperadorPage = lazy(() =>
  import('./pages/relatorio/produtos-vendidos-por-operador')
)
const RelatorioRaioXPage = lazy(() => import('./pages/relatorio/raio-x'))
const RelatorioRankingClientesPage = lazy(() =>
  import('./pages/relatorio/ranking-clientes')
)
const RelatorioSaldoDevedorCrediarioPage = lazy(() =>
  import('./pages/relatorio/saldo-devedor-crediario')
)
const RelatorioTaxaServicoPorDataPage = lazy(() =>
  import('./pages/relatorio/taxa-servico-por-data')
)
const RelatorioTaxaServicoPorGarcomPage = lazy(() =>
  import('./pages/relatorio/taxa-servico-por-garcom')
)
const RelatorioTicketMedioClientePorPracasPage = lazy(() =>
  import('./pages/relatorio/ticket-medio-cliente-por-pracas')
)
const RelatorioTrocaFinalizadorasPage = lazy(() =>
  import('./pages/relatorio/troca-finalizadoras')
)
const RelatorioVendaLucroMensalPage = lazy(() =>
  import('./pages/relatorio/venda-lucro-mensal')
)
const RelatorioVendaLucroPorProdutoPage = lazy(() =>
  import('./pages/relatorio/venda-lucro-por-produto')
)
const RelatorioVendaPorCategoriaPage = lazy(() =>
  import('./pages/relatorio/venda-por-categoria')
)
const RelatorioVendaPorFormaPgtoPage = lazy(() =>
  import('./pages/relatorio/venda-por-forma-pgto')
)
const RelatorioVendaPorOperadorPage = lazy(() =>
  import('./pages/relatorio/venda-por-operador')
)
const RelatorioVendaPorProdutoPage = lazy(() =>
  import('./pages/relatorio/venda-por-produto')
)
const RelatorioVendaPorProdutosAuditoriaPage = lazy(() =>
  import('./pages/relatorio/venda-por-produtos-auditoria')
)
const RelatorioVendasPage = lazy(() => import('./pages/relatorio/vendas'))
const RelatorioVendasCanceladasPage = lazy(() =>
  import('./pages/relatorio/vendas-canceladas')
)
const RelatorioVendasEadeliveryPage = lazy(() =>
  import('./pages/relatorio/vendas-eadelivery')
)
const RelatorioVendasPorClientePage = lazy(() =>
  import('./pages/relatorio/vendas-por-cliente')
)
const RelatorioVouchersPage = lazy(() => import('./pages/relatorio/vouchers'))

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ConfigProvider locale={ptBR}>
    <BrowserRouter>
      <Suspense fallback={<EAFullPageLoader />}>
        <Sentry.ErrorBoundary fallback={<EAUncaughtErrorFeedback />}>
          <AcessoProvider>
            <Routes>
              <Route path="/login" element={<AcessoLoginPage />} />
              <Route path="/logout" element={<AcessoLogoutPage />} />
              <Route
                path="/"
                element={
                  <AcessoLoginRequired>
                    <App />
                  </AcessoLoginRequired>
                }
              >
                <Route index element={<DashboardPage />} />

                <Route path="cadastro">
                  <Route
                    path="adquirente-tef/*"
                    element={<AdquirenteTefPages />}
                  />

                  <Route path="aliquota/*" element={<AliquotaPages />} />

                  <Route path="bairro/*" element={<BairroPages />} />

                  <Route path="bandeira-tef/*" element={<BandeiraTefPages />} />

                  <Route
                    path="cartao-consumo/*"
                    element={<CartaoConsumoPages />}
                  />

                  <Route path="cidade/*" element={<CidadePages />} />

                  <Route path="cofins/*" element={<CofinsPages />} />

                  <Route path="disp-movel/*" element={<DispMovelPages />} />

                  <Route path="estoque/">
                    <Route
                      path="local-estoque/*"
                      element={<LocalEstoquePages />}
                    />
                    <Route
                      path="transferencia-estoque/*"
                      element={<TransferenciaEstoquePages />}
                    />
                  </Route>

                  <Route
                    path="disponibilidade"
                    element={<DisponibilidadeProdutoPage />}
                  />

                  <Route path="ecf/*" element={<EcfPages />} />

                  <Route path="ficha/*" element={<FichaPages />} />

                  <Route path="forma-pgto/*" element={<FormaPgtoPages />} />

                  <Route path="gatilho/*" element={<GatilhoPages />} />

                  <Route path="grupo-opcao/*" element={<GrupoOpcaoPages />} />

                  <Route
                    path="grupo-produto/*"
                    element={<GrupoProdutoPages />}
                  />

                  <Route
                    path="impressora-comanda/*"
                    element={<ImpressoraComandaPages />}
                  />

                  <Route path="maquina-pos/*" element={<MaquinaPosPages />} />

                  <Route path="marca-sat/*" element={<MarcaSatPages />} />

                  <Route path="mesa/*" element={<MesaPages />} />

                  <Route
                    path="modalidade-tef/*"
                    element={<ModalidadeTefPages />}
                  />

                  <Route path="modelo-ecf/*" element={<ModeloEcfPages />} />

                  <Route
                    path="motivo-cancela/*"
                    element={<MotivoCancelaPages />}
                  />

                  <Route
                    path="motivo-transfere/*"
                    element={<MotivoTransferePages />}
                  />

                  <Route path="opcao/*" element={<OpcaoPages />} />

                  <Route
                    path="op-nao-fiscal/*"
                    element={<OpNaoFiscalPages />}
                  />

                  <Route path="pdv/*" element={<PdvPages />} />

                  <Route path="pessoa/*" element={<PessoaPages />} />

                  <Route path="pis/*" element={<PisPages />} />

                  <Route path="praca/*" element={<PracaPages />} />

                  <Route path="produto/*" element={<ProdutoPages />} />

                  <Route path="regiao/*" element={<RegiaoPages />} />

                  <Route path="sat/*" element={<SatPages />} />

                  <Route
                    path="setor-producao/*"
                    element={<SetorProducaoPages />}
                  />

                  <Route path="tabela-preco/*" element={<TabelaPrecoPages />} />

                  <Route path="tara-balanca/*" element={<TaraPages />} />

                  <Route path="unidade/*" element={<UnidadePages />} />
                </Route>

                <Route path="cfe">
                  <Route path="extrair-cfe" element={<ExtrairCfePage />} />

                  <Route
                    path="monitoramento-cfe/*"
                    element={<MonitoramentoCfePages />}
                  />
                </Route>

                <Route path="financeiro">
                  <Route path="sped" element={<SpedPages />} />
                  <Route path="entrada-nota/*" element={<EntradaNotaPages />} />
                  <Route path="financeiro/*" element={<FinanceiroPages />} />
                </Route>

                <Route
                  path="controle-acesso"
                  element={<ControleAcessoPage />}
                />

                <Route
                  path="estabelecimento/*"
                  element={<EstabelecimentoPages />}
                />

                <Route path="integracao/*" element={<IntegracaoPages />} />

                <Route path="parametros" element={<ParametrosPage />} />

                <Route path="relatorio">
                  <Route
                    path="acompanhamento-prevendas"
                    element={<RelatorioAcompanhamentoPrevendasPage />}
                  />

                  <Route
                    path="acompanhamento-prevendas-delivery"
                    element={<RelatorioAcompanhamentoPrevendasDeliveryPage />}
                  />

                  <Route
                    path="agenda-encomendas"
                    element={<RelatorioAgendaEncomendasPage />}
                  />

                  <Route path="dashboard" element={<DashboardPage />} />

                  <Route
                    path="ajuste-estoque"
                    element={<RelatorioAjusteEstoquePage />}
                  />

                  <Route
                    path="auditoria-taxa-servico"
                    element={<RelatorioAuditoriaTaxaServicoPage />}
                  />

                  <Route
                    path="auditoria-vendas-por-praca"
                    element={<RelatorioAuditoriaVendasPorPracaPage />}
                  />

                  <Route
                    path="auditoria-vendas-varejofacil"
                    element={<RelatorioAuditoriaVendasVarejofacilPage />}
                  />

                  <Route
                    path="cancelamento-prevendas"
                    element={<RelatorioCancelamentoPrevendasPage />}
                  />

                  <Route
                    path="clientes-que-nao-compraram"
                    element={<RelatorioClientesQueNaoCompraramPage />}
                  />

                  <Route
                    path="consumo-clientes"
                    element={<RelatorioConsumoClientesPage />}
                  />

                  <Route
                    path="crediario"
                    element={<RelatorioCrediarioPage />}
                  />

                  <Route path="curva-abc" element={<RelatorioCurvaAbcPage />} />

                  <Route
                    path="desempenho-atendente"
                    element={<RelatorioDesempenhoAtendentePage />}
                  />

                  <Route
                    path="desempenho-entregador"
                    element={<RelatorioDesempenhoEntregadorPage />}
                  />

                  <Route path="estoque" element={<RelatorioEstoquePage />} />

                  <Route
                    path="faturamento-diario-mensal"
                    element={<RelatorioFaturamentoDiarioMensalPage />}
                  />

                  <Route
                    path="flash-caixa"
                    element={<RelatorioFlashCaixaPage />}
                  />

                  <Route
                    path="informacoes-produtos"
                    element={<RelatorioInformacoesProdutosPage />}
                  />

                  <Route
                    path="listagem-clientes"
                    element={<RelatorioListagemClientesPage />}
                  />

                  <Route
                    path="operacoes-nao-fiscais"
                    element={<RelatorioOperacoesNaoFiscaisPage />}
                  />

                  <Route
                    path="produtos-por-cliente"
                    element={<RelatorioProdutosPorClientePage />}
                  />

                  <Route
                    path="produtos-vendidos-por-operador"
                    element={<RelatorioProdutosVendidosPorOperadorPage />}
                  />

                  <Route path="raio-x" element={<RelatorioRaioXPage />} />

                  <Route
                    path="ranking-clientes"
                    element={<RelatorioRankingClientesPage />}
                  />

                  <Route
                    path="saldo-devedor-crediario"
                    element={<RelatorioSaldoDevedorCrediarioPage />}
                  />

                  <Route
                    path="taxa-servico-por-data"
                    element={<RelatorioTaxaServicoPorDataPage />}
                  />

                  <Route
                    path="taxa-servico-por-garcom"
                    element={<RelatorioTaxaServicoPorGarcomPage />}
                  />

                  <Route
                    path="ticket-medio-cliente-por-pracas"
                    element={<RelatorioTicketMedioClientePorPracasPage />}
                  />

                  <Route
                    path="troca-finalizadoras"
                    element={<RelatorioTrocaFinalizadorasPage />}
                  />

                  <Route
                    path="venda-lucro-mensal"
                    element={<RelatorioVendaLucroMensalPage />}
                  />

                  <Route
                    path="venda-lucro-por-produto"
                    element={<RelatorioVendaLucroPorProdutoPage />}
                  />

                  <Route
                    path="venda-por-categoria"
                    element={<RelatorioVendaPorCategoriaPage />}
                  />

                  <Route
                    path="venda-por-forma-pgto"
                    element={<RelatorioVendaPorFormaPgtoPage />}
                  />

                  <Route
                    path="venda-por-operador"
                    element={<RelatorioVendaPorOperadorPage />}
                  />

                  <Route
                    path="venda-por-produto"
                    element={<RelatorioVendaPorProdutoPage />}
                  />

                  <Route
                    path="venda-por-produtos-auditoria"
                    element={<RelatorioVendaPorProdutosAuditoriaPage />}
                  />

                  <Route path="vendas" element={<RelatorioVendasPage />} />

                  <Route
                    path="vendas-canceladas"
                    element={<RelatorioVendasCanceladasPage />}
                  />

                  <Route
                    path="vendas-eadelivery"
                    element={<RelatorioVendasEadeliveryPage />}
                  />

                  <Route
                    path="vendas-por-cliente"
                    element={<RelatorioVendasPorClientePage />}
                  />

                  <Route path="vouchers" element={<RelatorioVouchersPage />} />
                </Route>
              </Route>

              <Route path="*" element={<NaoEncontradoPage />} />
            </Routes>
          </AcessoProvider>
        </Sentry.ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  </ConfigProvider>
)
